#home {
    // position: relative;
    // background: url('../../assets/headerImage1.jpg');
    // background-size: cover;
    // background-repeat: repeat;
    // background-position: center;

    .app__wrapper {
        position: relative;
        background: url('../../assets/headerImage1.jpg');
        background-size: cover;
        background-repeat: repeat;
        background-position: center;
    

        padding: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
            // background: linear-gradient(rgba(237, 242, 248, 1), rgba(237, 242, 248, 0));
            pointer-events: none;
            z-index: 0; /* Set a z-index to make sure it's behind other elements */
          }
        

        

        .copyright {
            display: none;
        }


    }
}
// #home::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
//     pointer-events: none;
//   }
.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }
    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width:100%;
        margin-right:0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }
    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }
    span {
        font-size: 2.5rem;

        @media screen and (min-width:2000px) {
            font-size: 5rem;
        }
    }

    @media screen and (max-width:1200px) {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
        width: 100%;
        height: 100px;
        border-radius:50%;
        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0,0,0,0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }
    div:nth-child(1){
        width: 100px;
        height: 100px;
    }
    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    @media  screen and (min-width:2000px) {
        div:nth-child(1){
            width: 400px;
            height: 400px;
        }
        div:nth-child(2){
            width: 170px;
            height: 170px;
        }
        div:nth-child(3){
            width: 200px;
            height: 200px;
        }
    }
    @media  screen and (max-width:1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div{
            margin: 1rem ;
        }
    }

}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 90%;

    }
    @media screen and (max-width:1200px){
       margin: 2rem 0; 
        
    }


}


.app__headr {
  position: relative;

  
  .app__headerName {
    position: relative;
    z-index: 1; /* Set a higher z-index to make sure it's above the gradient overlay */
    
  }
}




  .btn-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .btn {
    font-weight: 600;
    transition: all 300ms ease;
    padding: 1rem;
    width: 10rem;
    border-radius: 2rem;
    text-decoration: none;
    
  }
  
  .btn-color-1,
  .btn-color-2 {
    border: rgb(53, 53, 53) 0.1rem solid;
  }
  
  .btn-color-1:hover,
  .btn-color-2:hover {
    cursor: pointer;
  }
  
  
  .btn-color-2:hover {
    background: rgb(53, 53, 53);
    color: white;
  }
  
  .btn-color-1:hover {
    background: rgb(0, 0, 0);
  }
  
  .btn-color-2 {
    background: none;
    position: absolute; /* Position the link absolutely */
    bottom: -350px; /* Adjust bottom spacing */
    right: -480px; /* Adjust right spacing */

    
  }
  
  .btn-color-1 {
    background: none;
    position: absolute; /* Position the link absolutely */
    bottom: -350px; /* Adjust bottom spacing */
    left: -480px; /* Adjust right spacing */
    background: rgb(53, 53, 53);
    color: white;


    
  }


  .btn-color-2:hover {
    border: rgb(255, 255, 255) 0.1rem solid;
  }
  
  .btn-container {
    gap: 1rem;
  }
  